import { Link as GatsbyLink } from "gatsby-plugin-react-i18next";
import {
  LeadSection,
  Post,
  Card,
  Button,
  Section,
  CardGrid,
  Title,
} from "@lleed-partners/factor";
import {
  ArrowRight32,
  Time32,
  WatsonHealthMagnify32,
  Scales32,
  Tools32,
  Loop32,
  Locked32,
  UserMultiple32,
  Dashboard32,
} from "@carbon/icons-react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Icon } from "../fragments/Icon";
import { Layout } from "../layouts";
import { Helmet } from "gatsby-plugin-react-i18next";
import { asBackground } from "../fragments/styles";
import { StaticImage } from "gatsby-plugin-image";
import pattern from "../images/common/pattern.svg";

const patternStyle = {
  backgroundImage: "url('" + pattern + "')",
};

export default () => {
  const { t, language } = useI18next();

  return (
    <Layout
      navProps={{
        showSubNav: false,
      }}
    >
      <div style={patternStyle}>
        <Helmet>
          <meta
            name="description"
            content="Auditez les performances métier de votre entreprise. Lleed & Partners vous propose un audit gratuit afin de vous aider à prendre le pas de la transition digitale."
          />
          <title>Programme d'audit digital</title>
        </Helmet>
        <LeadSection
          intent="ghostInverted"
          padTop
          style={{
            minHeight: "100vh",
          }}
          title={"Auditez votre utilisation du digital"}
          subtitle={
            <>
              Vous souhaitez connaître vos performances métier et savoir si vos
              processus peuvent être améliorés ?
              <br />
              <br />
              Lleed & Partners propose un service d'audit digital gratuit qui
              permet aux entreprises d'identifier les marges de progression de
              leurs processus et d'y pourvoir.
            </>
          }
          cta={
            <>
              <Button
                size="large"
                intent="white"
                as="a"
                href="#audit"
                rightIcon={<Icon icon={<ArrowRight32 />} />}
              >
                Programme d'audit digital
              </Button>
            </>
          }
        />
        <div
          id="audit"
          style={{
            backgroundColor: "rgba(0,0, 0, .2)",
          }}
        >
          <Section
            intent="ghostInverted"
            title={
              <Title
                title="Les étapes clés"
                subtitle="pour améliorer vos processus quotidiens"
              />
            }
          >
            <CardGrid size="large" intent="white" bordered>
              <Card title="Mesurer" icon={<Icon icon={<Time32 />} />}>
                Il est difficile de s'améliorer si l'on ne sait pas où l'on en
                est. Commencez par dresser un constat du temps et de l'argent
                que vous dépensez dans vos opérations quotidiennes.
              </Card>
              <Card
                title="Analyser"
                icon={<Icon icon={<WatsonHealthMagnify32 />} />}
              >
                Utilisez les résultats de votre étude de performance pour
                détecter les tâches anormalement consommatrices de temps et
                d'argent. Par la suite, concentrez-vous sur celles qui ont le
                plus de potentiel à être étudiées à nouveau : des tâches
                courantes et peu efficaces.
              </Card>
              <Card title="Jugez" icon={<Icon icon={<Scales32 />} />}>
                A partir de l'analyse de performance, décidez sur quel éventuel
                levier vous souhaitez agir. En fonction de votre métier, il se
                peut que vos efforts soient plus efficients à la création
                d'autres processus qu'à l'amélioration d'existants. Il est
                néanmoins fréquent que certains résultats demandent une
                attention particulière.
              </Card>
              <Card title="Agissez" icon={<Icon icon={<Tools32 />} />}>
                Digitalisez les processus obsolètes et redondants qui peuvent
                être automatisés. Réduisez les allers-retours coûteux en mettant
                en place des processus de validation informatisés. Identifiez
                les anomalies et autres difficultés grâce à des solutions de
                machine learning. Découvrez des solutions variées adaptées à
                votre cas d'étude.
              </Card>
              <Card title="Itérez" icon={<Icon icon={<Loop32 />} />}>
                Répétez ce processus de manière régulière. Des processus
                inefficaces peuvent être créés n'importe quand, et des avancées
                technologiques peuvent rendre certains processus désormais
                obsolètes. Surtout, mesurez toujours vos performances pour
                détecter une amélioration ou une régression.
              </Card>
            </CardGrid>
          </Section>
          <Post intent="ghostInverted">
            <h2>
              La plupart des entreprises n'évaluent pas leur efficacité
              correctement
            </h2>
            <p>
              Mesurer votre performance n'est pas une tâche facile sans regard
              extérieur. Certains goulots d'étranglement clé peuvent être
              ignorés à cause d'idées préconçues ou d'habitudes.
            </p>
            <p>
              Lleed & Partners offre un service gratuit d'audit digital
              <sup>*</sup> pour vous aider à comprendre votre efficacité
              actuelle. Ce procédé est décrit dans les étapes ci-dessous.
            </p>
            <div style={{ height: "6rem" }}></div>
          </Post>
        </div>
        <Section
          title={
            <Title
              title="Lleed & Partners"
              subtitle="Programme d'audit digital"
              cta={
                <Button
                  size="large"
                  as={GatsbyLink}
                  to="/contact/business/"
                  rightIcon={<Icon icon={<ArrowRight32 />} />}
                >
                  Demander un audit digital gratuit
                </Button>
              }
            >
              Notre processus d'audit digital est composé de ces quelques étapes
              simples.
            </Title>
          }
          intent="dark"
        >
          <CardGrid intent="secondary" size="large">
            <Card icon={<Icon icon={<Locked32 />} />} title="Confidentialité">
              Le secret de nos clients est notre priorité. Avant de commencer
              l'audit, nous nous assurerons que vos informations sont entre de
              bonnes mains.
            </Card>
            <Card icon={<Icon icon={<UserMultiple32 />} />} title="Suivi">
              Une équipe d'experts métier de Lleed & Partners se rendra dans vos
              locaux et suivra vos employés pendant une période de temps donnée.
              Ils seront ainsi à même de fournir des données précises pour créer
              un rapport de performance.
            </Card>
            <Card icon={<Icon icon={<Dashboard32 />} />} title="Rapport">
              Les données collectés lors de la phase de suivi sont ensuite
              consignées dans un rapport pour une analyse future. Ces documents
              seront fournis gratuitement par Lleed & Partners pour discuter
              avec vous des améliorations pertinentes, s'il en existe.
            </Card>
            <Card icon={<Icon icon={<ArrowRight32 />} />} title="Continuité">
              Après la discussion avec votre équipe métier, un plan d'action
              sera dessiné. A partir de là, vous aurez le choix de continuer à
              travailler avec Lleed & Partners sur certains ou la totalité des
              points, de travailler avec une tierce partie, en interne ou de ne
              pas réaliser ces modifications.
            </Card>
          </CardGrid>
        </Section>
        <LeadSection
          backgroundMedia={
            <StaticImage
              src="https://images.pexels.com/photos/8550496/pexels-photo-8550496.jpeg"
              alt=""
              style={asBackground}
              loading="eager"
            />
          }
          inline
          title="Commencez maintenant"
          subtitle="Demandez un audit digital gratuit pour évaluer vos opérations et améliorer votre performance."
          cta={
            <Button
              intent="white"
              size="large"
              as={GatsbyLink}
              to="/contact/business/"
              rightIcon={<Icon icon={<ArrowRight32 />} />}
            >
              Contacter Lleed & Partners
            </Button>
          }
        />
        <Section
          intent="ghostInverted"
          style={{
            backgroundColor: "rgba(0, 0, 0, .2)",
          }}
        >
          <Post intent="ghostInverted">
            <p>
              <small>
                *Le programme d'audit digital gratuit de Lleed & Partners est
                sujet à conditions. Lleed & Partners se réserve le droit de
                refuser ou d'approuver des candidats sans aucune justification
                de sa part.
              </small>
            </p>
          </Post>
        </Section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
