export const asBackground = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
}

export const aspectRatio = (ratio) => {
  return {
    paddingBottom: (1 / ratio) * 100 + "%",
    height: 0
  }
}
